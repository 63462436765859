<template>
  <datatable
    id="daftar_pengiriman"
    :columns="columns"
    :scroll-x="false"
    :total="stockMovements.meta.page.total"
    :perPage="stockMovements.meta.page.perPage"
    :currentPage="stockMovements.meta.page.currentPage"
    :meta="stockMovements.meta"
    :is-empty="!stockMovements.data.length"
    cursor
    @pagechanged="onPageChanged"
  >
    <template v-slot:tbody="{ classes }">
      <tbody :class="classes.tbody">
        <tr
          v-for="stockMovement in stockMovements.data"
          :key="stockMovement.id"
          :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
          @click="onClickDetail(stockMovement)"
        >
          <td :class="[classes.td]">
            <span class="block font-bold text-gray-900">{{
              stockMovement.attributes.code
            }}</span>
            <span class="text-xs text-gray-500">{{
              getSingleIncluded(
                stockMovements,
                stockMovement.relationships['order'].data.id
              ).attributes.origin_code
            }} / {{
              getSingleIncluded(
                stockMovements,
                stockMovement.relationships['order'].data.id
              ).attributes.destination_code
            }}</span>
          </td>
          <td :class="[classes.td]">{{
              getSingleIncluded(
                stockMovements,
                stockMovement.relationships['destination-office'].data.id
              ).attributes.code
            }}
          </td>
          <td :class="[classes.td]">{{
              stockMovement.attributes.updatedAt | formatDate
            }}
          </td>
          <td :class="[classes.td]">{{
              stockMovement.attributes.packet_qty | toCurrency
            }}
          </td>
          <td :class="[classes.td]">{{
              stockMovement.attributes.product_qty | toCurrency
            }}
          </td>
          <td :class="[classes.td]">
            <base-badge :color="stockMovement.attributes.is_shipped ? 'green' : 'red'">{{ stockMovement.attributes.is_shipped ? 'Terkirim' : 'Belum Dikirim' }}</base-badge>
          </td>
        </tr>
      </tbody>
    </template>
  </datatable>
</template>

<script>
export default {
  props: {
    stockMovements: {
      type: Object,
      required: true,
    },
    destinationOfficeLabel: {
      type: String,
      default: 'Stockist'
    }
  },
  emits: ['change-page', 'click-detail'],
  computed: {
    columns() {
      return [
        { id: 'code', name: 'Nomor Pengemasan' },
        { id: 'destination_office_code', name: `Kode ${this.destinationOfficeLabel}` },
        { id: 'updated_at', name: 'Tanggal Pengemasan' },
        { id: 'packet_qty', name: 'Total Koli' },
        { id: 'product_qty', name: 'Total Barang' },
        { id: 'status', name: 'Status' },
      ];
    },
  },
  methods: {
    onPageChanged(page) {
      this.$emit('change-page', page);
    },
    onClickDetail(simpleStockMovement) {
      this.$emit('click-detail', simpleStockMovement);
    },
  },
};
</script>
