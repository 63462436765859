<template>
  <div class="mx-auto mt-8 px-4 sm:px-4 lg:px-8">
    <div class="rounded-lg">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <div class="relative rounded-md shadow-sm">
            <select
              v-model="filter.is_shipped"
              @change="onReload"
              class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
            >
              <option :value="null">Semua</option>
              <option :value="true">Terkirim</option>
              <option :value="false">Belum Dikirim</option>
            </select>
          </div>
          <base-search
            v-model="filter.search"
            @input="onReloadDebounce"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              v-if="getStockMovements.meta"
              id="daftar_order"
              :total="getStockMovements.meta.page.total"
              :perPage="getStockMovements.meta.page.perPage"
              :currentPage="getStockMovements.meta.page.currentPage"
              :meta="getStockMovements.meta"
              cursor
              @pagechanged="onPageChanged"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nomor Pengemasan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Tanggal Pengemasan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getStockMovements.data?.length > 0">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getStockMovements.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click.prevent="onViewStockMovement(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <div class="text-sm font-bold text-gray-900">
                        {{ getStockMovementIncluded(data).attributes.code }} /
                        {{
                          getStockMovementIncluded(data).attributes.order_code
                        }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getStockMovementIncluded(data).attributes
                          .origin_office_name
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        dayjs(
                          getStockMovementIncluded(data).attributes.updatedAt
                        ).format('ll LT')
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        getStockMovementIncluded(data).attributes
                          .product_summaries
                          ? Object.keys(
                              getStockMovementIncluded(data).attributes
                                .product_summaries
                            ).length
                          : ''
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        v-if="
                          getStockMovementIncluded(data).attributes.is_shipped
                        "
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Terkirim
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                      >
                        Belum Dikirim
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="5"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>

    <supplier-pengiriman-modal
      :visible="visibleDetail"
      @close="visibleDetail = false"
      @refresh="onRefresh"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import SupplierPengirimanModal from '@/components/supplier/SupplierPengirimanModal.vue';
import { stockMovementRead } from '@/mixins/stock-movement/stock-movement-read.mixin';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    SupplierPengirimanModal,
    BaseSearch,
  },
  mixins: [stockMovementRead],

  data() {
    return {
      visibleDetail: false,
      filter: {
        search: null,
        is_shipped: false,
      },
      page: {
        number: 1,
        size: 5,
      },
    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'stock_movements/getLoading',
    }),
    readStockMovementsParams() {
      return {
        include: 'stock-movement',
        'fields[simple-stock-movements]': 'stock-movement',
        'fields[stock-movements]':
          'code,order_code,origin_office_name,updatedAt,product_summaries,is_shipped',
        'pagination[type]': 'cursor',
        'page[limit]': 5,
        'filter[is_shipped]': this.filter.is_shipped,
        'filter[search]': this.filter.search,
        'filter[origin_office_category_id]': 6,
        'filter[destination_office_category_id]': 1,
      };
    },
    viewStockMovementParams() {
      return {
        'fields[stock-movements]':
          'order,origin-office,product_summaries,packets,product-batches,code,updatedAt,is_received,is_shipped,is_valid_for_shipment',
        'fields[orders]': 'origin_code,createdAt',
        include: 'order,origin-office,product-batches',
      };
    },
  },
  methods: {
    dayjs,
    onStockMovementViewed() {
      this.visibleDetail = true;
    },
    onRefresh() {
      this.visibleDetail = false;

      this.onReload();
    },
  },
  created() {
    this.onCreated();
  },
};
</script>
