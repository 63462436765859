<template>
  <Datatable
    :total="getStockMovements.meta.page.total"
    :perPage="getStockMovements.meta.page.perPage"
    :currentPage="getStockMovements.meta.page.currentPage"
    @pagechanged="handleChangePage"
    :is-empty="!getStockMovements.data?.length"
    id="daftar_pengiriman"
    cursor
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Nomor Kemasan
          </th>
          <th
            scope="col"
            class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Produk
          </th>
          <th
            scope="col"
            class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Berat
          </th>
          <th
            scope="col"
            class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Harga
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            STATUS
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
            v-if="isStockClassified"
          >
            Status Pilah
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            v-if="isShipped"
          >
            Status Pengiriman
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <div v-if="isLoading" class="flex items-center text-center">
        <loading></loading>
      </div>
      <tbody v-if="getStockMovements.data?.length > 0">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getStockMovements.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click.prevent="handleClickItem(data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            <div class="text-sm font-bold text-gray-900">
              {{ data.attributes.code }} /
              {{
                getRelationships(
                  getStockMovements,
                  data.relationships['stock-movement'].data.id
                ).attributes.order_code
              }}
            </div>
            <div class="text-xs font-light text-gray-300">
              {{ dayjs(data.attributes.createdAt).format('ll LT') }}
            </div>
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
          >
            {{
              getRelationships(
                getStockMovements,
                data.relationships['stock-movement'].data.id
              ).attributes.product_summaries
                ? Object.keys(
                    getRelationships(
                      getStockMovements,
                      data.relationships['stock-movement'].data.id
                    ).attributes.product_summaries
                  ).length
                : null
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
          >
            {{ countStockMovementWeight(data) | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
          >
            {{ countStockMovementPrice(data) | toCurrency }}
            {{ data.attributes.final_price | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
          >
            <base-badge v-if="data.attributes.is_received">
              Diterima
            </base-badge>
            <base-badge v-else color="yellow"> Belum diterima </base-badge>
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
            v-if="isStockClassified"
          >
            <base-badge
              :color="data.attributes.is_stock_classified ? 'green' : 'yellow'"
              >{{
                data.attributes.is_stock_classified
                  ? 'Terpilah'
                  : 'Belum Terpilah'
              }}</base-badge
            >
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            v-if="isShipped"
          >
            <span
              v-if="data.attributes.is_shipped"
              class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
            >
              Terkirim
            </span>
            <span
              v-else
              class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
            >
              Belum dikirim
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>
<script>
import { mapGetters } from 'vuex';
import { sum } from '@/utils/array';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import BaseBadge from '@/components/base/BaseBadge';

export default {
  name: 'StockMovementTable',
  components: {
    Datatable,
    BaseBadge,
  },
  props: {
    isStockClassified: Boolean,
    isShipped: Boolean,
  },
  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      isLoading: 'stock_movements/getLoading',
    }),
  },
  methods: {
    dayjs,
    handleChangePage(value) {
      this.$emit('page-change', value);
    },
    handleClickItem(value) {
      this.$emit('click-row', value);
    },
    countStockMovementPrice(stockMovement) {
      if (!stockMovement.attributes.packets) {
        return 0;
      }
      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.product_price);
      });
    },
    countStockMovementWeight(stockMovement) {
      if (!stockMovement.attributes.packets) {
        return 0;
      }
      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.total_weight);
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
};
</script>
