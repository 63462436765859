<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="value"
      style="animation-duration: 0.3s"
      class="fixed inset-0 z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      id="validasi_modal"
    >
      <div
        class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
        >
          <form @submit.prevent="validate">
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Validasi Pengiriman
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Lengkapi form dibawah ini untuk memvalidasi pengiriman
                    kemasan
                  </p>
                </div>
              </div>
              <div class="my-4 space-y-3">
                <div class="field-inset-default w-full bg-gray-100">
                  <label
                    for="kode_po"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Nama Kemasan
                  </label>
                  <p
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  >
                    {{ getStockMovement.data.attributes.code }}
                  </p>
                </div>
                <div
                  v-if="
                    getStockMovement.data.attributes.shipment_type === 'delivery'
                  "
                  class="space-y-3"
                >
                  <div class="field-inset-default w-full">
                    <label
                      for="no_resi"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Nomor Resi
                    </label>
                    <input
                      v-model="dataPengiriman.no_resi"
                      type="text"
                      name="no_resi"
                      id="no_resi"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan nomor resi yang didapatkan dari kurir"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Diambil oleh
                    </label>
                    <select
                      v-model="dataPengiriman.expedition"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      id="expedition"
                    >
                      <option :value="null">Pilih Ekspedisi</option>
                      <option
                        v-for="expedition in getOfficeExpeditions.data"
                        :key="expedition.id"
                        :value="expedition.id"
                      >
                        <template
                          v-if="
                            expedition.relationships['expedition-detail'].data
                              ?.id
                          "
                        >
                          {{
                            getRelationships(
                              getOfficeExpeditions,
                              expedition.relationships['expedition'].data?.id
                            )?.attributes?.name
                          }}
                          -
                          {{
                            getRelationships(
                              getOfficeExpeditions,
                              expedition.relationships['expedition-detail'].data
                                .id
                            ).attributes.address
                          }}
                        </template>
                      </option>
                    </select>
                  </div>
                  <span class="mt-2 text-sm">
                    Untuk menambah ekspedisi
                    <router-link
                      to="/gudang/setup?tab=Ekspedisi"
                      class="text-blue-600 hover:underline"
                      >Klik disini</router-link
                    >
                  </span>
                </div>
                <div
                  v-else-if="
                    getStockMovement.data.attributes.shipment_type === 'pickup'
                  "
                >
                  <div class="field-inset-default w-full">
                    <label
                      for="taken_by"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Diambil oleh
                    </label>
                    <input
                      v-model="dataPengiriman.taken_by"
                      type="text"
                      name="taken_by"
                      id="taken_by"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan nama yang mengambil barang"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
              <base-button fullwidth type="submit"> Ya </base-button>
              <base-button
                fullwidth
                color="white"
                @click="onClose"
                type="button"
              >
                Tutup
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/base/BaseButton.vue';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'ModalValidasiPengiriman',
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: Boolean,
    },
    dataPengiriman: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'stock_movements/getLoading',
      getStockMovement: 'stock_movements/getStockMovement',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    ...mapActions({
      sendStockMovement: 'stock_movements/sendStockMovement',
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
    }),
    getRelationships(resource, id) {
      let data = resource.included.find(function (el) {
        return el.id === id;
      });
      return data;
    },
    validate() {
      let payload;
      if (
        this.getStockMovement.data.attributes.shipment_type === 'delivery'
      ) {
        payload = {
          data: {
            type: 'stock-movements',
            id: this.getStockMovement.data.id,
            attributes: {
              airwaybill_number: this.dataPengiriman.no_resi,
            },
            relationships: {
              'office-expedition': {
                data: {
                  type: 'office-expeditions',
                  id: this.dataPengiriman.expedition,
                },
              },
            },
          },
        };
      } else if (
        this.getStockMovement.data.attributes.shipment_type === 'pickup'
      ) {
        payload = {
          data: {
            type: 'stock-movements',
            id: this.getStockMovement.data.id,
            attributes: {
              taken_by: this.dataPengiriman.taken_by,
            },
          },
        };
      }
      this.sendStockMovement(payload).then((response) => {
        if (response) {
          this.$router.go(-1);
        }
        this.openDetail = false;
      });
    },
    onClose() {
      this.$emit('close');
    },
  },
  created() {
    this.fetchOfficeExpeditionsByOffice({
      office_id: StorageService.getUser().office_id,
      params: {
        'filter[isActive]': true,
        include: 'expedition-detail,expedition',
      },
    });
  },
};
</script>
